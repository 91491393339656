document.addEventListener('DOMContentLoaded', () => {
    window.addEventListener('hashchange', (e) => {
        if (!e.oldURL.includes('#')) {
            // entering modal
            window.history.replaceState({ isNewModal: true }, '', window.location.href);
        }
    });

    const modalCloseButton = Array.from(document.getElementsByClassName('js-modal-close'));
    modalCloseButton.forEach((element) => {
        element.addEventListener('click', () => {
            if (window.history.state && window.history.state.isNewModal) {
                window.history.back();
            }
        });
    });

    // To be compliant with WAI-ARIA Best Practices, close all dialogs when hitting the escape key.
    document.addEventListener('keyup', (e) => {
        if (e.key === 'Escape') {
            modalCloseButton.forEach((modal) => modal.click());
        }
    });
});

// Handling JustWatch widgets
document.addEventListener('DOMContentLoaded', () => {
    // The mutation observer is responsible for removing background
    // spinner when iframe is loaded
    const mutationObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            const target = mutation.target;
            const iframe = target.querySelector('iframe');
            iframe.onload = () => {
                const sibling = target.parentElement.querySelector('.loader');
                if (sibling) {
                    sibling.remove();
                }
            };
        });
    });

    const lazyAssetObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const lazyElement = entry.target;
                lazyElement.removeAttribute('data-jw-widget-tmp');
                lazyElement.setAttribute('data-jw-widget', '');

                mutationObserver.observe(lazyElement.parentElement, { childList: true });

                window.JustWatch.reloadWidgets();
                lazyAssetObserver.unobserve(entry.target);
            }
        });
    });

    const lazyResources = Array.from(document.querySelectorAll('[data-jw-widget-tmp]'));
    lazyResources.forEach((resource) => {
        lazyAssetObserver.observe(resource);
    });
});

document.addEventListener('DOMContentLoaded', () => {
    const collapsibleElements = Array.from(document.getElementsByClassName('collapsible'));
    collapsibleElements.forEach((element) => {
        element.addEventListener('click', () => {
            const content = document.getElementById('collapsible-content');
            content.classList.toggle('u-none');
            element.classList.toggle('outline');
        });
    });

    // Handle modal being displayed
    const modalShownObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const lazyElement = entry.target;
                const newFocusField = lazyElement.querySelectorAll('[autofocus]')[0];
                newFocusField.focus();
            } else {
                // Return focus to what would normally be focused out of the modal
                document.querySelectorAll('[autofocus]')[0].focus();
            }
        });
    });

    const modalObservables = Array.from(document.getElementsByClassName('modal'));
    modalObservables.forEach((resource) => {
        modalShownObserver.observe(resource);
    });
});

// eslint-disable-next-line no-unused-vars
function remove(elem) {
    const errorBar = elem.parentNode;
    const errorBarParent = errorBar.parentNode;
    errorBarParent.removeChild(errorBar);
}

function show(element) {
    element.classList.remove('u-none');
}

function hide(element) {
    element.classList.add('u-none');
}

// eslint-disable-next-line no-unused-vars
function spinButton() {
    const button = document.getElementById('searchSubmitButton');
    button.classList.add('animated');
    button.classList.add('loading');
    button.disabled = true;
}

// eslint-disable-next-line no-unused-vars
function liveCountrySearch(searchQuery) {
    const countries = document.querySelectorAll('.country');
    for (let i = 0; i < countries.length; i += 1) {
        if (countries[i].innerText.toLowerCase().includes(searchQuery.toLowerCase())) {
            show(countries[i]);
        } else {
            hide(countries[i]);
        }
    }
}

// eslint-disable-next-line no-unused-vars
function liveServicesSearch(searchQuery) {
    const items = document.querySelectorAll('.js-service');
    items.forEach((item) => {
        if (item.dataset.serviceName.toLowerCase().includes(searchQuery.toLowerCase())) {
            show(item);
        } else {
            hide(item);
        }
    });
}

function setCookie(name, value, days) {
    const expires = `expires=${new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
}

// eslint-disable-next-line no-unused-vars
function sortTitles(selectObject, requestMethod) {
    const selectedOrder = [...selectObject.options]
        .filter((option) => option.selected)
        .map((s) => s.value)[0];

    const [sortField, order] = selectedOrder.split('_');

    if (requestMethod !== 'POST') {
        const baseUrl = new URL(window.location.href);
        if (sortField === 'position') {
            baseUrl.searchParams.delete('sort');
        } else {
            baseUrl.searchParams.set('sort', selectedOrder);
        }
        window.history.replaceState({}, null, baseUrl);
    }

    function getCleanSortValue(element) {
        return Number(
            element
                .querySelector(`[data-sort-field="${sortField}"]`)
                .textContent.replace(/min$/, ''), // remove 'min' suffix from runtime
        );
    }

    function compare(a, b) {
        const aValue = getCleanSortValue(a);
        const bValue = getCleanSortValue(b);
        if (aValue < bValue) {
            return order === 'desc' ? 1 : -1;
        }
        if (aValue > bValue) {
            return order === 'desc' ? -1 : 1;
        }
        return 0;
    }

    const rowsList = document.getElementById('cards');
    const movieRows = Array.from(rowsList.children);
    if (movieRows.length === 0) {
        return;
    }
    movieRows
        .map((row) => rowsList.removeChild(row))
        .sort(compare)
        .forEach((row) => rowsList.appendChild(row));
}

// eslint-disable-next-line no-unused-vars
function toggleNetflixAvailable(selectObject, numAllTitles, requestMethod) {
    const filterType = selectObject.id;

    const availableServices = Array.from(document.getElementsByName('serviceCheckbox'));
    const selectedServices = availableServices.filter((option) => option.checked);
    const selectedServicesSlugs = selectedServices.map((option) => option.value);

    const availableTypes = Array.from(document.getElementById('types').options);
    const selectedTypes = availableTypes.filter((option) => option.selected);
    const selectedTypesValues = selectedTypes.map((option) => option.value);

    const showUnstreamable = document.getElementById('showUnstreamableCheckbox').checked;

    function isAvailable(service) {
        return selectedServicesSlugs.includes(service);
    }

    function isStreamable(row) {
        const services = Array.from(row.querySelectorAll('[data-streaming-service]'));
        return services.length > 0;
    }

    function isVisible(row) {
        const services = Array.from(row.querySelectorAll('[data-streaming-service]'));

        return (
            services.some((s) => isAvailable(s.dataset.streamingService)) ||
            (showUnstreamable && services.length === 0)
        );
    }

    const resultsRows = Array.from(document.getElementsByClassName('results-row'));

    const numStreamableTitles = resultsRows.filter((row) => isStreamable(row)).length;
    const numUnstreamableTitles = resultsRows.filter((row) => !isStreamable(row)).length;
    const unstreamableTitlesLoaded = numStreamableTitles + numUnstreamableTitles === numAllTitles;

    const pageUrl = new URL(window.location.href);
    if (showUnstreamable) {
        pageUrl.searchParams.set('showUnstreamable', 'true');
        if (!unstreamableTitlesLoaded) {
            const element = document.getElementById('unstreamableSpinnerPlaceholder');
            element.classList.add('clock-loading');

            window.location.href = pageUrl.toString();
            return;
        }
    } else {
        pageUrl.searchParams.delete('showUnstreamable');
    }
    window.history.replaceState({}, null, pageUrl);

    const visibleRows = resultsRows
        .filter((row) => selectedTypesValues.includes(row.dataset.type))
        .filter(isVisible);
    visibleRows.forEach((row) => {
        show(row);

        const rowServices = Array.from(row.querySelectorAll('[data-streaming-service]'));
        rowServices.filter((s) => isAvailable(s.dataset.streamingService)).forEach((s) => show(s));
        rowServices.filter((s) => !isAvailable(s.dataset.streamingService)).forEach((s) => hide(s));
    });

    const menuServices = Array.from(document.querySelectorAll('[data-menu-streaming-service]'));
    menuServices.filter((s) => isAvailable(s.dataset.menuStreamingService)).forEach((s) => show(s));
    menuServices
        .filter((s) => !isAvailable(s.dataset.menuStreamingService))
        .forEach((s) => hide(s));

    const numVisibleTitles = visibleRows.length;

    const hiddenRows = resultsRows.filter((row) => !visibleRows.includes(row));
    hiddenRows.forEach((row) => hide(row));

    // Deal with summary
    const numVisibleTitlesSpan = document.getElementById('numVisibleTitlesSpan');
    numVisibleTitlesSpan.textContent = `${numVisibleTitles} / ${numAllTitles}`;

    // There might be a 'no titles' message left over, in case someone
    // is coming from a URL with a service that doesn't have any titles available
    const noResultsMessage = document.getElementById('no-results-message');
    if (noResultsMessage && numVisibleTitles > 0) {
        noResultsMessage.style.display = 'none';
    }

    if (filterType === 'types' && requestMethod !== 'POST') {
        const newUrl = new URL(window.location.href);
        if (selectedTypes.length === 0 || selectedTypes.length === availableTypes.length) {
            newUrl.searchParams.delete(filterType);
        } else {
            newUrl.searchParams.set(filterType, selectedTypesValues.join(','));
        }
        window.history.replaceState({}, null, newUrl.toString());

        return;
    }

    // Deal with the services cookie
    if (selectedServices.length && selectedServices.length < availableServices.length) {
        setCookie('selectedServices', selectedServicesSlugs.join(','), 30);
    }

    // Deal with window.href and titles
    const listTitleElement = document.getElementById('watchlist-title');
    const listTitleRaw = listTitleElement.innerText.split(' on ')[0];

    const [newTitleSuffix, newUrlSuffix] = (() => {
        if (selectedServices.length === 1) {
            return [
                ` on ${selectedServices[0].dataset.serviceName}`,
                `-on-${selectedServices[0].value}`,
            ];
        }
        if (selectedServices.length === 0) {
            return ['', ''];
        }
        return ['on streaming services', '-on-streaming-services'];
    })();

    if (requestMethod !== 'POST') {
        const newUrl = new URL(window.location.href);
        const [basePath] = newUrl.pathname.split('-on-').slice(0);
        newUrl.pathname = `${basePath}${newUrlSuffix}`;
        window.history.replaceState({}, null, newUrl);
    }
    const newTitle = `${listTitleRaw} ${newTitleSuffix}`;
    document.title = `${newTitle} | Vidchuck`;
    listTitleElement.innerText = newTitle;
}

function serviceCheckboxChanged(serviceCheckbox) {
    const serviceCheckboxes = Array.from(document.getElementsByName('serviceCheckbox'));
    // when action is coming from the 'clear all' button, not a checkbox
    if (serviceCheckbox === null) {
        serviceCheckboxes.forEach((service) => {
            // eslint-disable-next-line no-param-reassign
            service.checked = false;
        });
    }

    const numAll = serviceCheckboxes.length;
    const numChecked = serviceCheckboxes.filter((checkbox) => checkbox.checked).length;

    document.getElementById(
        'numVisibleServicesSpan',
    ).textContent = `${numChecked} / ${numAll} selected`;

    document.getElementById('saveServicesButton').disabled = numChecked === 0;
    document.getElementById('clear-all-services-button').disabled = numChecked === 0;
}

document.addEventListener('DOMContentLoaded', () => {
    const checkboxes = document.getElementsByName('serviceCheckbox');
    Array.from(checkboxes).forEach((checkbox) => {
        checkbox.addEventListener('change', () => {
            serviceCheckboxChanged(checkbox);
        });
    });
});
